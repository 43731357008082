import React from 'react';
import iconK from './iconK.svg';
import kodjoImage from './kodjo.jpg';
import './App.css';
import Mailto from "./MailTo";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={iconK} className="App-logo" alt="logo Kodjoscribe" />
        <p>
          Kodjoscribe est disponible pour&nbsp;
            <a href="https://github.com/orthomatique/kodjoscribe/releases/latest/download/kodjoscribe.exe">
                Windows
            </a>,&nbsp;
            <a href="https://github.com/orthomatique/kodjoscribe/releases/latest/download/kodjoscribe.dmg">
                Mac
            </a> et&nbsp;
            <a href="https://github.com/orthomatique/kodjoscribe/releases/latest/download/kodjoscribe.AppImage">
                Linux
            </a>.
        </p>
        <p>
            Pour une aide à l'installation / prise en main, <Mailto
                email="info@kodjoscribe.fr"
                obfuscate
                headers={{
                    subject:"Je souhaite une aide à l'installation / prise en main de kodjoscribe",
                    body:"Merci de me recontacter pour une aide à l'installation / prise en main de Kodjoscribe. \r\nMes coordonnées : \r\nMes disponibilités : "
                }}
            > contactez-nous ici
            </Mailto>
        </p>
        <img src={kodjoImage} className="App-logo" alt="aperçu livre kodjo" />
        <a
          className="App-link"
          href="https://www.deboecksuperieur.com/ouvrage/9782807324459-kodjo-evaluer-la-comprehension-en-lecture-elementaire-et-college"
          target="_blank"
          rel="noopener noreferrer"
        >
          Kodjo : évaluer la compréhension en lecture - Elémentaire et collège
        </a>
      </header>
    </div>
  );
}

export default App;
